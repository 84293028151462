import { RowLimitSelector } from 'components/RowLimitSelector/RowLimitSelector'
import React, { useEffect, useRef, useState } from 'react'
import PaginatedTable from 'semantic-table/dist/components/PaginatedTable'

const CustomPaginatedTable = (props) => {
	const tableRef = useRef(null)
	const [rowLimit, setRowLimit] = useState(10)

	useEffect(() => {
		const applyNotranslate = () => {
			if (tableRef.current) {
				const tds = tableRef.current.querySelectorAll('td')
				tds.forEach((td) => td.classList.add('notranslate'))
			}
		}

		const timeoutId = setTimeout(applyNotranslate, 0)

		return () => clearTimeout(timeoutId)
	}, [props.rows])

	const customRenderCell = (row, column) => {
		return (
			<td className='notranslate'>
				{column.render ? column.render(row) : row[column.accessor]}
			</td>
		)
	}

	const customColumns = props.columns.map((column) => ({
		...column,
		Cell: (row) => customRenderCell(row, column),
	}))

	// const sortedRows = props.rows.sort(
	// 	(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
	// )

	const propsObject = {
		...props,
		rowLimit,
	}

	return (
		<div ref={tableRef}>
			<RowLimitSelector props={{ rowLimit, setRowLimit }} />

			<PaginatedTable {...propsObject} columns={customColumns} />
		</div>
	)
}

export default CustomPaginatedTable
