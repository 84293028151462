import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react'

export const RowLimitSelector = ({ props }) => {
	const { rowLimit, setRowLimit } = props

	if (rowLimit === undefined || typeof setRowLimit !== 'function') {
		throw new Error('rowLimit and setRowLimit props are required.')
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100px',
				gap: '3px',
				marginTop: '10px',
			}}
		>
			<span style={{ fontWeight: '500' }}>Visualizando</span>

			<Select
                value={rowLimit}
				onChange={(_, { value }) => {
					setRowLimit(value)
				}}
				placeholder='10'
				options={[
					{
						text: '10',
						value: 10,
					},
					{
						text: '50',
						value: 50,
					},
					{
						text: '100',
						value: 100,
					},
				]}
			/>
		</div>
	)
}

RowLimitSelector.propTypes = {
	props: PropTypes.shape({
		rowLimit: PropTypes.number.isRequired,
		setRowLimit: PropTypes.func.isRequired,
	}).isRequired,
};
