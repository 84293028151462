import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './tab_menu_bar.module.css'
import { Button, Popup } from 'semantic-ui-react'

export const TabMenuBar = ({ props }) => {
	const [isClicked, setIsClicked] = useState(false)
	const iconName = isClicked ? 'arrow circle down' : 'arrow circle right'

	const scrollContainerRef = useRef(null)

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current

		if (!scrollContainer) return

		const handleWheel = (e) => {
			if (scrollContainer) {
				e.preventDefault()

				// Scroll speed multiplier
				const scrollSpeed = 1.5

				// Scroll horizontally based on vertical wheel movement
				scrollContainer.scrollLeft += e.deltaY * scrollSpeed
			}
		}

		// Add event listener
		scrollContainer.addEventListener('wheel', handleWheel, { passive: false })

		// Clean up
		return () => {
			scrollContainer.removeEventListener('wheel', handleWheel)
		}
	}, [isClicked])

	useEffect(() => {
		const handleUnload = () => {
			const pathname = window.location.pathname
			const existing = JSON.parse(localStorage.getItem('shortcut_list')) || []

			const updated = existing.filter((item) => {
				return item.url != pathname.replace(/^\/|\/$/g, '')
			})

			localStorage.setItem('shortcut_list', JSON.stringify(updated))
		}

		window.addEventListener('beforeunload', handleUnload)

		return () => {
			window.removeEventListener('beforeunload', handleUnload)
		}
	}, [])

	useEffect(() => {
		let divMainContent = document.querySelector('.main-content')
		let divTabMenuBar = document.querySelector('.tabMenuBar')
		
		if(divTabMenuBar){
			divMainContent.style.paddingTop = '0px'
		}
		
	}, [])

	const modulesList = useMemo(() => {
		let list = []
		if (props.data.user.modules) {
			list = props.data.user.last_visits
		}
		return list
	}, [props.data.user.last_visits])

	const updateStorage = (linkData) => {
		let newStorageObject = {
			id: linkData.id,
			url: linkData.url,
		}

		const existing = JSON.parse(localStorage.getItem('shortcut_list')) || []
		if (existing.find((item) => item.url == linkData.url)) {
			props.data.history.push(`/${linkData.url}`)
		} else {
			existing.push(newStorageObject)
			localStorage.setItem('shortcut_list', JSON.stringify(existing))
			window.open(`${window.location.origin}/${linkData.url}`, '_blank')
		}
	}

	if (window.location.pathname == '/') {
		return null
	}

	return (
		<div className={`${styles.tab_scroller_container} tabMenuBar`}>
			<Popup
				content={
					isClicked ? 'Ocultar Accesos Rapidos' : 'Mostrar Accesos rapidos'
				}
				trigger={
					<Button onClick={() => setIsClicked(!isClicked)} icon={iconName} />
				}
			/>

			<div
				ref={scrollContainerRef}
				className={`${styles.linksContainer} ${
					isClicked ? styles.linksContainerOpen : ''
				}`}
			>
				<div className={styles.linksWrapper}>
					{props.data.user?.last_visits?.length ? (
						modulesList.map((link, index) => (
							<span
								key={index}
								className={styles.link}
								onClick={() => {
									updateStorage(link)
								}}
							>
								{link.name}
							</span>
						))
					) : (
						<span className={styles.list_empty}>
							No hay accesos directos en la lista
						</span>
					)}
				</div>
			</div>
		</div>
	)
}
